import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'

export default class SelectableService {
  public service: Service
  public serviceType: ServiceType | null
  public numberOfServicesInCategory: number;

  public constructor (service: Service, serviceType: ServiceType | null = null, numberOfServicesInCategory = 0) {
    this.service = service
    this.serviceType = serviceType
    this.numberOfServicesInCategory = numberOfServicesInCategory
  }

  public getDuration () {
    if (this.serviceType) {
      return this.serviceType.duration
    }

    return this.service.duration
  }

  public getPrice () {
    if (this.serviceType) {
      return this.serviceType.price
    }

    return this.service.price
  }

  public getName () {
    if (this.serviceType) {
      return this.service.name + ' (' + this.serviceType.name + ')'
    }

    return this.service.name
  }

  public isGroupType (): boolean {
    return this.service.isGroupType()
  }
}

import Service from '@/calendesk/models/DTO/Response/Service'

export default class AutocompleteServiceItem {
  public text: string
  public value: Service | null
  public header: string | null
  public disabled: boolean
  public divider: boolean

  public constructor (text: string, value: Service | null, header: string | null = null, divider = false, disabled = false) {
    this.text = text
    this.value = value
    this.header = header
    this.divider = divider
    this.disabled = disabled
  }
}

import mixins from 'vue-typed-mixins'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import Section from '@/calendesk/sections/section/mixins/Section'
import SelectableService from '@/calendesk/models/SelectableService'
import BookingDialogModel from '@/calendesk/models/BookingDialogModel'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import CServicesField from '@/calendesk/sections/section/booking/components/CServicesField.vue'

export default mixins(Section).extend({
  components: {
    DefaultButton,
    CServicesField
  },
  data () {
    return {
      serviceSelectable: null as SelectableService | null,
      selectedDate: null as string | null,
      minDate: new Date().toISOString().substr(0, 10),
      menu: false
    }
  },
  computed: {
    getServices () {
      if (this.data.configuration.wb_service_list__services__ && this.data.configuration.wb_service_list__services__.length > 0) {
        return this.data.configuration.wb_service_list__services__
      } else {
        return this.services
      }
    }
  },
  methods: {
    openBookingDialog () {
      if (this.serviceSelectable && this.serviceSelectable.service) {
        const bookingDialogModel = new BookingDialogModel(
          this.serviceSelectable.service,
          null,
          this.serviceSelectable.serviceType,
          null,
          this.selectedDate,
          null,
          null)

        this.openDialog(new Dialog(
          true,
          DialogTypes.CREATE_BOOKING,
          null,
          DialogSize.LARGE,
          false,
          'center',
          {
            dialogData: bookingDialogModel,
            configurationData: this.data
          })
        )
      }
    }
  }
})













































































































import { mapActions, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Category from '@/calendesk/models/DTO/Response/Category'
import AutocompleteServiceItem from '@/calendesk/models/AutocompleteServiceItem'
import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import SelectableService from '@/calendesk/models/SelectableService'
export default mixins(DraftElement).extend({
  name: 'CServicesField',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: SelectableService,
      default: null
    },
    filteredServices: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      items: [] as AutocompleteServiceItem[],
      isLoading: false,
      selectedService: null as Service | null,
      selectedServiceType: null as ServiceType | null
    }
  },
  computed: {
    ...mapGetters({
      categoryAndServices: 'service/getCategoryAndServices'
    })
  },
  watch: {
    selectedService () {
      (this.$refs.mainSelect as any).blur()
    },
    value () {
      this.setup()
    }
  },
  created () {
    if (this.categoryAndServices) {
      this.setup()
    } else {
      this.fetchCategoryAndServices().then(() => {
        this.setup()
      })
    }
  },
  methods: {
    ...mapActions({
      fetchCategoryAndServices: 'service/fetchCategoryAndServices'
    }),
    setup () {
      if (this.categoryAndServices) {
        this.items = this.mapItems(this.categoryAndServices)

        if (this.value) {
          this.selectedService = this.value.service
          this.selectedServiceType = this.value.serviceType
        }
      }
    },
    selectServiceAndItsType (service: Service, type: ServiceType) {
      this.$emit('input', new SelectableService(service, type))

      this.selectedService = service
      this.selectedServiceType = type
    },
    serviceSelectedEvent (service: Service) {
      this.$emit('input', new SelectableService(service))

      this.selectedService = service
      this.selectedServiceType = null
    },
    mapItems (categories: Category[]) {
      const items = Array<AutocompleteServiceItem>()
      let index = 0
      categories.forEach((category: Category) => {
        let categoryIncludesFilteredServices = false

        if (this.filteredServices.length > 0) {
          category.services?.forEach((service: Service) => {
            const found = this.filteredServices.find((item: any) => item.id === service.id)

            if (found) {
              categoryIncludesFilteredServices = true
            }
          })
        }

        if (categoryIncludesFilteredServices) {
          // Category name
          if (index > 0) {
            items.push(new AutocompleteServiceItem('', null, null, true, true))
          }
          items.push(new AutocompleteServiceItem(category.name, null, category.name, true, true))

          // Services
          category.services?.forEach((service: Service) => {
            const found = this.filteredServices.find((item: any) => item.id === service.id)

            if (found || this.filteredServices.length === 0) {
              items.push(new AutocompleteServiceItem(service.name, service))
            }
          })
        }

        index++
      })

      return items
    }
  }
})
